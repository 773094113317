<template>
    <div>
        <v-container class="paper-border">
            <v-row no-gutters>
                <v-col cols="6" class="d-flex flex-column mt-0">
                    <span class="font-weight-bold">{{ $t('message.seller') }}</span>
                    <span>{{ preview.office }}</span>
                </v-col>
                <v-col cols="2" class="d-flex flex-column mt-0">
                    <span class="font-weight-bold">{{ $t('message.marketing') }}</span>
                    <span>{{ preview.sales }}</span>
                </v-col>
                <v-col cols="2" class="d-flex flex-column mt-0">
                    <span class="font-weight-bold">{{ $t('message.date') }}</span>
                    <span>{{ preview.date }}</span>
                </v-col>
                <v-col cols="2" class="d-flex flex-column text-right mt-0">
                    <span class="font-weight-bold">{{ $t('message.number') }}</span>
                    <span>{{ preview.number }}</span>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6" class="d-flex flex-column mt-0">
                    <span class="font-weight-bold">{{ $t('message.buyer') }}</span>
                    <span>{{ preview.buyer }}</span>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6" class="d-flex flex-column mt-0">
                    <span class="font-weight-bold">{{ $t('message.termsOfPayment') }}</span>
                    <span>{{ preview.buyerTerms }}</span>
                </v-col>
                <v-col cols="6" class="d-flex flex-column text-right mt-0">
                    <span class="font-weight-bold">{{ $t('message.shipment') }}</span>
                    <span>{{ preview.etd }}</span>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6" class="d-flex flex-column text-right mt-0 offset-6">
                    <span class="font-weight-bold">{{ $t('message.countryOriginDestination') }}</span>
                    <span>{{ [preview.origin, preview.destination].filter(Boolean).join(' / ') }}</span>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6" class="d-flex flex-column text-right mt-0 offset-6">
                    <span class="font-weight-bold">{{ $t('message.termsOfDelivery') }}</span>
                    <span>{{ preview.incoterm }}</span>
                </v-col>
            </v-row>
            <v-simple-table
                class="pt-2"
                dense
            >
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="px-0 font-sm border-bottom-2">{{ $t('message.specification') }}</th>
                            <th class="px-0 font-sm border-bottom-2">{{ $t('message.size') }}</th>
                            <th class="px-0 font-sm border-bottom-2">{{ $t('message.grade') }}</th>
                            <th class="px-0 font-sm text-right width-8-pct border-bottom-2">{{ $t('message.quantity') }}</th>
                            <th class="px-0 font-sm text-right width-8-pct border-bottom-2">{{ $t('message.unitPrice') }}</th>
                            <th class="px-0 font-sm text-right width-8-pct border-bottom-2">{{ $t('message.amount') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(item,index) in NcrItems">
                            <tr v-if="index == 0">
                                <td colspan="6" class="px-0 border-0">{{ getUiDescription(item) }}</td>
                            </tr>
                            <tr v-else-if="index > 0 && getUiDescription(NcrItems[index - 1]) != getUiDescription(item)">
                                <td colspan="6" class="px-0 border-0">{{ getUiDescription(item) }}</td>
                            </tr>
                            <tr>
                                <td class="px-0 border-0"></td>
                                <td class="px-0 border-0">{{ item.Size.title != null ? item.Size.title : $t('message.seeRemarks') }}</td>
                                <td class="px-0 border-0">{{ item.Grade.title != null  ? item.Grade.title : $t('message.seeRemarks') }}</td>
                                <td class="px-0 text-right border-0">{{ formatThisNumber(item.NcrItem.itemqty, '0,0.00') + ' ' + uofmQty.find((uofm)=>uofm.Measurement.id == item.NcrItem.measurement_id).Measurement.title }}</td>
                                <td class="px-0 text-right border-0">{{ formatThisNumber(item.NcrItem.unitprice, '0,0.00') }}</td>
                                <td class="px-0 text-right border-0">{{ formatThisNumber(item.NcrItem.amount, '0,0.00') }}</td>
                            </tr>
                        </template>
                        <tr>
                            <td colspan="6" class="px-0 pt-10 border-0">
                                <div style="white-space: pre-line" v-if="preview.remarks != null">
                                    <div v-if="preview.remarks.indexOf('Remark') == -1">{{ $t('message.remarks') }}</div>
                                    <div>{{ preview.remarks.trim() }}</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="px-0 border-bottom-2 px-0 pt-3">
                                <div class="d-flex flex-column" v-if="[2,3,4,5,6].includes(preview.fsc)">
                                    <span>{{ $t('message.certCode') + ' : ' + getCertification(preview.fsc,'cert_no') }}</span>
                                    <span>{{ $t('message.expiryDate') + ' : ' + getCertification(preview.fsc,'cert_expiry_date') }}</span>
                                </div>
                            </td>
                            <td class="border-bottom-2 pt-3"></td>
                            <td class="border-bottom-2 pt-3">{{ $t('message.total') + ' ' + preview.containers }}</td>
                            <td class="border-bottom-2 text-right px-0 pt-3">
                                <span v-for="(total,index) in itemsTotalQty">
                                    {{ formatThisNumber(total.Total.qty, uofmPrecision(total.Total.uofm)) + ' '+ total.Total.uofm }}
                                </span>
                            </td>
                            <td class="border-bottom-2 pt-3"></td>
                            <td class="border-bottom-2 text-right px-0 pt-3">{{ formatThisNumber(itemsTotalAmount, '0,0.00') }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-row no-gutters v-if="false">
                <v-col cols="4">{{ $t('message.remarks') }}:</v-col>
                <v-col cols="8"><div v-html="preview.remarks"></div></v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import {mapFieldsPrefixed, mapMultiRowFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {mapActions, mapGetters} from "vuex";
import {formatDate, numberFormat} from "../../helpers/helpers";
// import {productGroupsNew} from "@/store/modules/appic/constants";

export default {
    name: "ContractRequestPreview",
    data(){
        return {
            preview: {
                buyer: null,
                buyerTerms: null,
                certification: null,
                container: null,
                currency: 'USD',
                date: null,
                destination: '',
                eta: null,
                etd: null,
                fsc: null,
                incoterm: null,
                isExport: false,
                number: '',
                office: null,
                origin: '',
                pol: null,
                pod: null,
                remarks: null,
                sales: null,
                supplier: null,
                supplierTerms: null,
                type: null
            }
        }
    },
    computed: {
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('ncr',{
            Ncr__containercount: 'Ncr.containercount',
            Ncr__containersize_id: 'Ncr.containersize_id',
            Ncr__remarks: 'Ncr.remarks',
            Ncr__currency_id: 'Ncr.currency_id',
            Ncr__customer_id: 'Ncr.customer_id',
            Ncr__destination_id: 'Ncr.destination_id',
            Ncr__etadate: 'Ncr.etadate',
            Ncr__etddate: 'Ncr.etddate',
            // Ncr__fsc: 'Ncr.fsc',
            Ncr__incoterm_id: 'Ncr.incoterm_id',
            Ncr__is_export: 'Ncr.is_export',
            Ncr__loadingport_id: 'Ncr.loadingport_id',
            Ncr__ncrdate: 'Ncr.ncrdate',
            Ncr__office_id: 'Ncr.office_id',
            Ncr__paymentstatement_id: 'Ncr.paymentstatement_id',
            Ncr__popaymentstatement_id: 'Ncr.popaymentstatement_id',
            Ncr__productorigin_id: 'Ncr.productorigin_id',
            Ncr__salescontact_id: 'Ncr.salescontact_id',
            Ncr__salestype_id: 'Ncr.salestype_id',
            Ncr__shippingport_id: 'Ncr.shippingport_id',
            Ncr__shippingweek: 'Ncr.shippingweek',
            Ncr__supplier_id: 'Ncr.supplier_id',
            Ncr__title: 'Ncr.title',
        },'statePrefix'),
        ...mapMultiRowFieldsPrefixed('ncritem', {
            NcrItems: 'NcrItems',
        },'statePrefix'),
        ...mapFieldsPrefixed('ncritem',{
            itemsTotalAmount: 'itemsTotalAmount',
            itemsTotalCost: 'itemsTotalCost',
            itemsTotalQty: 'itemsTotalQty'
        },'statePrefix'),
        ...mapGetters('buyer', {
            allBuyers: 'allBuyers',
        }),
        ...mapGetters('certification',{
            allCertifications: 'allCertifications'
        }),
        ...mapGetters('country',{
            allCountries: 'allCountries'
        }),
        ...mapGetters('currency',{
            allCurrencies: 'allCurrencies'
        }),
        ...mapGetters('grades', {
            allGrades: 'allGrades'
        }),
        ...mapGetters('mc', {
            allMoistureContent: 'allMoistureContent'
        }),
        ...mapGetters('paymentterm',{
            allBuyerPaymentTerms: 'allBuyerPaymentTerms',
            allSupplierPaymentTerms: 'allSupplierPaymentTerms',
        }),
        ...mapGetters('productgroup',{
            allProductGroups: 'allProductGroups'
        }),
        ...mapGetters('salescolleague',{
            allSalesColleagues: 'allSalesColleagues'
        }),
        ...mapGetters('shippingport', {
            allShippingDischargePorts: 'allShippingDischargePorts',
            allShippingLoadingPorts: 'allShippingLoadingPorts'
        }),
        ...mapGetters('species', {
            allSpecies: 'allSpecies'
        }),
        ...mapGetters('supplier', {
            allSuppliers: 'allSuppliers'
        }),
        ...mapGetters([
            'certifications',
            // 'certificationsNew',
            'containerSizes',
            'currencies',
            'incoterms',
            'offices',
            // 'productGroupsNew',
            'salesTypes',
            'uofmQty'
        ]),
        addMode: function () {
            return this.$route.matched.some(({name}) => name === 'add_ncr')
        },
        currencyFormatPrecision () {
            return this.allCurrencies.find((currency) => currency.Currency.id === this.Ncr__currency_id)?.Currency?.formatPrecision
        },
        Ncr__fsc() {
            let fscId  = null
            if(this.NcrItems.length > 0){
                this.NcrItems.forEach(item => {
                    if(item.NcrItem.certification_id != null && [0,7,8].includes(item.NcrItem.certification_id) == false){
                        fscId = item.NcrItem.certification_id
                    }
                })
            }
            return fscId
        },
        updateMode: function () {
            return this.$route.matched.some(({name}) => name === 'update_ncr')
        }
    },
    methods: {
        ...mapActions('currency', {
            getAllCurrencies: 'getAllCurrencies'
        }),
        formatDate,
        formatThisNumber(value,format){
            return numberFormat(value,format)
        },
        // getDescription(item){
        //     let description = ''
        //     if(this.Ncr__is_export == 1){ //special consideration for export contracts
        //         if(item.Spec.id == null){
        //             let product = this.productGroupsNew.find(p => p.Productgroup.id == item.NcrItem.productgroup_id)
        //             if(product) {
        //                 description = item.NcrItem.description.replace(product.Productgroup.title,'')
        //                 description = description.trim()
        //             } else {
        //                 description = item.NcrItem.description
        //             }
        //         } else {
        //             description = item.NcrItem.description.trim()
        //         }
        //     } else {
        //         description = item.NcrItem.description
        //     }
        //     return description
        // },
        // getCertification(certificationId) {
        //     let certificationObj = this.allCertifications.find(c => c.Certification.id == certificationId)
        //     if(certificationObj){
        //         if(certificationObj.Certification.title != 'None') {
        //             return ', ' + certificationObj.Certification.title
        //         } else {
        //             return ''
        //         }
        //     }
        //     return ''
        // },
        getCertification(certificationId, certificationInfo) {
            let certificationObj = this.allCertifications.find(c => c.Certification.id == certificationId)
            if(certificationObj){
                return certificationObj?.Certification[certificationInfo]
            }
            return ''
        },
        getProductGroup(productGroupId) {
            let productObj = this.allProductGroups.find(p => p.ProductGroup.id == productGroupId)
            if(productObj){
                return productObj.ProductGroup.title + ', '
            }
            return ''
        },
        getUiDescription(item) {
            let uiDescription = []

            let species = this.allSpecies.find(s => s.Species.id == item.NcrItem.species_id)
            if(species) uiDescription.push(species.Species.title)

            if(item.NcrItem.description != null) {
                let product = this.allProductGroups.find(p => p.ProductGroup.id == item.NcrItem.productgroup_id)
                if (product && item.NcrItem.description.includes(product.ProductGroup.title) == false) uiDescription.push(product.ProductGroup.title)
            } else {
                let product = this.allProductGroups.find(p => p.ProductGroup.id == item.NcrItem.productgroup_id)
                if (product) uiDescription.push(product.ProductGroup.title)
            }

            if(item.NcrItem.description != '') uiDescription.push(item.NcrItem.description)

            // let grade = this.allGrades.find(g => g.Grade.id == item.NcrItem.grade_id)
            // if(grade) uiDescription.push(grade.Grade.title)

            let certification = this.allCertifications.find(c => c.Certification.id == item.NcrItem.certification_id)
            if(certification && item.NcrItem.description != null && item.NcrItem.description.includes(certification.Certification.title) == false && certification.Certification.title != 'None') uiDescription.push(certification.Certification.title)
            if(certification && item.NcrItem.description == null && certification.Certification.title != 'None') uiDescription.push(certification.Certification.title)

            let mc = this.allMoistureContent.find(mc => mc.Mc.id == item.NcrItem.mc_id)
            if(mc && item.NcrItem.description == null) uiDescription.push(mc.Mc.title)

            uiDescription = uiDescription.filter(Boolean).join(", ")
            return uiDescription
        },
        uofmPrecision (uofm) {
            if(uofm) {
                if(isNaN(uofm)) {
                    if(uofm.trim() == 'M3'){
                        return '0,0.000'
                    } else {
                        return this.uofmQty.find((u) => u.Measurement.title == uofm.trim())?.Measurement?.formatPrecision
                    }
                } else {
                    if(uofm == 1){
                        return '0,0.000'
                    } else {
                        return this.uofmQty.find((u) => u.Measurement.id == uofm)?.Measurement?.formatPrecision
                    }
                }
            } else {
                return '0,0.00'
            }
        },
    },
    created(){
        if(this.allCurrencies.length == 0) this.getAllCurrencies()
        if(this.Ncr__containercount != null){
            let containerSize = this.containerSizes.find(c => c.Containersize.id == this.Ncr__containersize_id)
            if([1,3,5].includes(this.Ncr__containersize_id)){
                this.preview.containers = this.Ncr__containercount + " X " + containerSize?.Containersize.title
            } else {
                this.preview.containers = containerSize?.Containersize.title
            }
        }
        if(this.Ncr__customer_id != null){
            this.preview.buyer = this.allBuyers.find(c => c.Customer.id == this.Ncr__customer_id)?.Customer.otsname
        }
        if(this.Ncr__currency_id != null){
            this.preview.currency = this.allCurrencies.find(c => c.Currency.id == this.Ncr__currency_id)?.Currency.code
        }
        if(this.Ncr__destination_id != null){
            this.preview.destination = this.allCountries.find(c => c.Country.id ==  this.Ncr__destination_id)?.Country.name
        }
        if(this.Ncr__etadate != null){
            this.preview.eta = formatDate(this.Ncr__etadate)
        }
        if(this.Ncr__shippingweek != null){
            this.preview.etd = this.Ncr__shippingweek
        } else if(this.Ncr__etddate != null){
            this.preview.etd = formatDate(this.Ncr__etddate)
        }
        if(this.Ncr__fsc != null){
            this.preview.certification = this.certifications.find(c => c.Certification.id == this.Ncr__fsc)?.Certification.title
            this.preview.fsc = this.Ncr__fsc
        }
        if(this.Ncr__incoterm_id != null){

            this.preview.incoterm = this.incoterms.find(i => i.Incoterm.id ==  this.Ncr__incoterm_id)?.Incoterm.title

            let incoterm = this.incoterms.find((incoterm)=>incoterm.Incoterm.id == this.Ncr__incoterm_id)?.Incoterm?.title;
            let incotermport = '';
            switch(this.Ncr__incoterm_id){
                case null:
                    incotermport = ''
                    break;
                case 1:
                case 4:
                case 6:
                    if(this.Ncr__loadingport_id != null) {
                        let loadingPort = this.allShippingLoadingPorts.find(s => s.Shippingport.id ==  this.Ncr__loadingport_id)
                        incotermport = ' ' + loadingPort.Shippingport.title
                    }
                    break
                case 2:
                case 3:
                case 7:
                    if(this.Ncr__shippingport_id != null) {
                        let shippingPort = this.allShippingDischargePorts.find(s => s.Shippingport.id ==  this.Ncr__shippingport_id)
                        incotermport = ' ' + shippingPort.Shippingport.title
                    }
                    break;
                default:
                    incotermport = ''
            }
            this.preview.incoterm = incoterm + incotermport;
        }
        if(this.Ncr__is_export != null){
            this.preview.isExport = this.Ncr__is_export == 1 ? true : false
        }
        if(this.Ncr__loadingport_id != null){
            this.preview.pol = this.allShippingLoadingPorts.find(s => s.Shippingport.id ==  this.Ncr__loadingport_id)?.Shippingport.title
        }
        if(this.Ncr__ncrdate != null){
            this.preview.date = formatDate(this.Ncr__ncrdate)
        }
        if(this.Ncr__office_id != null){
            this.preview.office = this.offices.find(o => o.Office.id ==  this.Ncr__office_id)?.Office.title
        }
        if(this.Ncr__paymentstatement_id != null){
            this.preview.buyerTerms = this.allBuyerPaymentTerms.find(c => c.Paymentterm.id ==  this.Ncr__paymentstatement_id)?.Paymentterm.title
        }
        if(this.Ncr__popaymentstatement_id != null){
            this.preview.supplierTerms = this.allSupplierPaymentTerms.find(c => c.Paymentterm.id ==  this.Ncr__popaymentstatement_id)?.Paymentterm.title
        }
        if(this.Ncr__productorigin_id != null){
            this.preview.origin = this.allCountries.find(c => c.Country.id ==  this.Ncr__productorigin_id)?.Country.name
        }
        if(this.Ncr__remarks != null){
            this.preview.remarks = this.Ncr__remarks
        }
        if(this.Ncr__salescontact_id != null){
            this.preview.sales = this.allSalesColleagues.find(s => s.Salesperson.id == this.Ncr__salescontact_id)?.Salesperson.name
        }
        if(this.Ncr__salestype_id != null){
            this.preview.type = this.salesTypes.find(s => s.Salestype.id == this.Ncr__salestype_id)?.Salestype.code
        }
        if(this.Ncr__shippingport_id != null){
            this.preview.pod = this.allShippingDischargePorts.find(s => s.Shippingport.id ==  this.Ncr__shippingport_id)?.Shippingport.title
        }
        if(this.Ncr__supplier_id != null){
            this.preview.supplier = this.allSuppliers.find(s => s.Supplier.id == this.Ncr__supplier_id)?.Supplier.otsname
        }
        if(this.Ncr__title != null){
            this.preview.number = this.Ncr__title
        }
    }
}
</script>

<style>
.paper-border {
    border: dashed 1px darkgrey;
}
.border-top-2 {
    border-top: 2px solid black;
}
.border-bottom-2 {
    border-bottom: 2px solid black !important;
}
.v-data-table tbody td {
    vertical-align: middle !important;
    height: 25px !important;
}
</style>